.flag {
	margin-top: -5px;
    margin-left: -10px;
	width: 18px;
	height: 12px;
	background:url("../images/flags.png") no-repeat
}

.flag.flag-de {background-position: -18px 0}
.flag.flag-en {background-position: 0 -12px}

.sidebar-flag {
	margin-left: 0px;
}

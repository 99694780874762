/* Entry point for your PostCSS build */

@import "fomantic-ui/dist/semantic.min.css";
@import '@fontsource/source-sans-pro';
@import "bootstrap/dist/css/bootstrap.css";
@import "bootstrap/dist/css/bootstrap-theme.css";
@import "bootstrap-colorpicker/dist/css/bootstrap-colorpicker.css";
@import "cards.scss";
@import "dashboards.scss";
@import "flags.scss";
@import "jquery.raty.css";
@import "multiselect.css";
@import "producers.scss";
@import "products.scss";
@import "restaurants.scss";
@import "themes.scss";
@import "users.scss";

.dashboard {
  text-align:center;
  margin-top:30px;
}

.dashboard h1 {
  text-align:center;
}

.dashboard ul {
  margin-top:50px;
  margin-bottom:160px;
  float: none;
  display: inline-block;
  overflow: hidden;
}

p.description {
  margin: 0px 0 27px;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  color: #999;
  text-align: center;
}

.pull-right {
  float: right !important;
}


@font-face {
  font-family: MuseoSans-500;
  src: url("../fonts/MuseoSans_500.otf") format("opentype");
}

html, body { height: 100%; margin: 0; padding: 0; }

body { 
padding-top: 20px;
padding-left: 20px;

font-family: "Source Sans Pro", serif;
}

div.previewimg {
width: 120px;
padding-left:150px;
padding-top:20px;
padding-bottom:20px;
}

.checkbox_label {
padding-right:20px;
}

.navbar-inverse .navbar-inner {
background-color: #1b1b1b;
background-image: -moz-linear-gradient(top, #444444, #222222);
background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#444444), to(#222222));
background-image: -webkit-linear-gradient(top, #444444, #222222);
background-image: -o-linear-gradient(top, #444444, #222222);
background-image: linear-gradient(to bottom, #444444, #222222);
background-repeat: repeat-x;
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff444444', endColorstr='#ff222222', GradientType=0);
background-color: #1b1b1b;
background-image: -moz-linear-gradient(top, #444444, #222222);
background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#444444), to(#222222));
background-image: -webkit-linear-gradient(top, #444444, #222222);
background-image: -o-linear-gradient(top, #444444, #222222);
background-image: linear-gradient(to bottom, #444444, #222222);
background-repeat: repeat-x;
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff444444', endColorstr='#ff222222', GradientType=0);
border-color: #252525;
}

* {
outline:none !important;
-moz-outline: none !important;
}
/* Main */
body {
overflow-x: hidden;
margin-top: -9px;
background-color: #444444 !important;
}

a:hover {
text-decoration: none;
}


/* Content */
#content {
  background: none repeat scroll 0 0 #FAFAFA;
  margin-top: -10px;
  margin-left: 200px;
  margin-right: 0;
  
  padding-top: 10px;
  padding-bottom: 25px;
  position: relative;
  min-height: 100vh;
  width: auto;
}

.page-header h1 {
  color: #555555;
  font-size: 28px;
  font-weight: normal;
  text-shadow: 0 1px 0 #000000;
}

.container-fluid .row:first-child {
margin-top: 20px;
//margin-left: -40px;
}


/* Sidebar Navigation */
#sidebar {
  display: block;
  float: left;
  position: relative;
  width: 220px;
  z-index: 16;
  top:-10px;
  left:-20px;
}

#sidebar > ul {
list-style: none;
margin: 10px 0 0;
padding: 0;
position: absolute;
width: 220px;
}
#sidebar > ul > li {
display: block; 
position: relative;
}

#sidebar > ul > li.active {
box-shadow: 0 2px 4px rgba(0,0,0,0.2) inset;
}

#sidebar > ul > li > a {
padding: 10px 0 10px 15px;
display: block;
color: #AAAAAA;
}

#sidebar > ul > li > a > i {
margin-right: 10px;
}

// #sidebar > ul > li.active > a {
// background: url("../img/menu-active.png") no-repeat scroll right center transparent !important;
// }

#sidebar > ul > li > a > .label {
margin: 0 20px 0 0;
float: right;
padding: 3px 5px 2px;
box-shadow: 0 1px 2px rgba(0,0,0,0.5) inset, 0 1px 0 rgba(255,255,255,0.2);
}

#sidebar > ul ul {
display: none;
margin: 0;
padding: 0;
box-shadow: 0 0 3px rgba(0,0,0,0.5) inset;
}
#sidebar > ul li.open ul {
display: block;
}
#sidebar > ul ul li a{
padding: 10px 0 10px 25px;
display: block;
color: #777777;
}

#sidebar > ul ul li:first-child a {
border-top: 0;
}
#sidebar > ul ul li:last-child a {
border-bottom: 0;
}

#sidebar .user {
padding: 10px 20px 10px 20px;
}

#sidebar .logo {
padding: 10px 35px 20px 25px;
//background-color: #04a0e0;
background-color: #ffffff;
border-right: 1px #AAAAAA solid;
}

#sidebar .logo img {
  max-width: 100%;
  width: auto\9;
  height: auto;
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic
}

.sidebar-dropdown {
height:50px;
padding: 10px 25px 10px 25px;
display:block;
}

#sidebar > ul > li > a > img {
margin-right:6px;
}

/* Responsive design */
@media (max-width: 480px) {
#sidebar {
  float: none;
  width: 100% !important;
}
#sidebar > a {
  padding: 7px 20px 7px 15px;
  box-shadow: 0 1px 0 #666666;
  margin-bottom: 1px;
  display: block !important;
  text-transform: uppercase;
  color: #eeeeee;
  font-size: 10px;
}
// #sidebar > a > i {
//   margin-right: 10px;
//   background-image: url('../img/glyphicons-halflings-white.png');
// }
#sidebar > ul {
  display: none;
  margin: -2px 0 0 !important;
  width: 100% !important;
  box-shadow: 0 6px 6px rgba(0,0,0,0.4);
}
#sidebar > ul > li.active > a {
  background: none !important;
}
#sidebar > ul > li > a > span {
  display: inline !important;
}
}

@media (min-width: 481px) and (max-width: 767px) {
#sidebar, #sidebar > ul {
  width: 43px;
}
#sidebar > ul ul {
  display: none;
  position: absolute;
  left:50px;
  top: 0;
  min-width: 150px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.7);
  border-radius: 5px;   
  list-style: none;
}
#sidebar > ul ul li a {
  white-space: nowrap;
  padding: 10px 25px;
}
#sidebar > ul ul:before {
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  content: "";
  display: inline-block;
  left: -6px;
  position: absolute;
  top: 11px;
}
#sidebar > ul ul:after {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  content: "";
  display: inline-block;
  left: -5px;
  position: absolute;
  top: 12px;
  }
#sidebar > a {
  display: none !important;
}
#sidebar > ul > li.open.submenu > a {
  border-bottom: none !important;
}
#sidebar > ul > li > a > span {
  display: none;
}
}


#sidebar  > ul {
background-color: #444444;
}

#header {
  box-shadow: 0 1px 0 #333333;
background-color: #333333;
  background-image: -webkit-gradient(linear, 0 0%, 0 100%, from(#3F3F3F), to(#222222));
  background-image: -webkit-linear-gradient(top, #3F3F3F 0%, #222222 100%);
  background-image: -moz-linear-gradient(top, #3F3F3F 0%, #222222 100%);
background-image: -ms-linear-gradient(top, #3F3F3F 0%, #222222 100%);
background-image: -o-linear-gradient(top, #3F3F3F 0%, #222222 100%);
background-image: linear-gradient(top, #3F3F3F 0%, #222222 100%);
  border-bottom: 1px solid #555555;
}

#search input[type=text], #search button {
background-color: #222222;
}
#search input[type=text]:focus {
color: #777777;
}

#sidebar > ul {
border-top: 1px solid #393939;
border-bottom: 1px solid #4E4E4E;
}
#sidebar > ul > li {
border-top: 1px solid #4E4E4E;
border-bottom: 1px solid #393939;
}
#sidebar > ul > li.active {
  background-color: #3a3a3a;
background-image: -webkit-gradient(linear, 0 0%, 0 100%, from(#333333), to(#3F3F3F));
background-image: -webkit-linear-gradient(top, #333333 0%, #3F3F3F 100%);
background-image: -moz-linear-gradient(top, #333333 0%, #3F3F3F 100%);
background-image: -ms-linear-gradient(top, #333333 0%, #3F3F3F 100%);
background-image: -o-linear-gradient(top, #333333 0%, #3F3F3F 100%);
background-image: linear-gradient(top, #333333 0%, #3F3F3F 100%);
}

#sidebar > ul > li.open.submenu > a {
border-bottom: 1px solid #393939;
}
#sidebar > ul > li.open.active.submenu > a {
border-bottom: 1px solid #2A2A2A;
}
#sidebar > ul > li > a > .label {
background-color: #333333;
}
#sidebar > ul > li > a:hover {
background-color: #4A4A4A;
}
#sidebar > ul ul {
border-top: 1px solid #4E4E4E;
background-color: #2A2A2A;
}
#sidebar > ul ul li a {
border-top: 1px solid #333333;
border-bottom: 1px solid #202020;
}
#sidebar > ul ul li a:hover, #sidebar > ul ul li.active a {
color: #CCCCCC;
background-color: #272727;
}

#tooltip {
position: absolute;
display:none;
border: none;
padding: 3px 8px;
border-radius: 3px;
font-size: 10px;
background-color: #222222;
color: #ffffff;
z-index: 25;
}

/* Hacks */

#products_length.dataTables_length {
margin: 20px;
} 

#products_filter.dataTables_filter {
margin: 20px;
}

#producers_info.dataTables_info {
margin-left: 20px;
margin-bottom: 20px;
}

#producers_length.dataTables_length {
margin: 20px;
} 

#producers_filter.dataTables_filter {
margin: 20px;
}

#products_info.dataTables_info {
margin-left: 20px;
margin-bottom: 20px;
}

div.dataTables_paginate {
margin-right: 20px !important;
}
table.dataTable thead .sorting_disabled {
background: none;
}

.cancel-on-png, .cancel-off-png, .star-on-png, .star-off-png, .star-half-png {
  font-size: 2em;
}

@font-face {
  font-family: "raty";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/raty.eot");
  src: url("../fonts/raty.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/raty.svg#raty") format("svg");
  src: url("../fonts/raty.ttf") format("truetype");
  src: url("../fonts/raty.woff") format("woff");
}

.cancel-on-png, .cancel-off-png, .star-on-png, .star-off-png, .star-half-png {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "raty";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
}

.cancel-on-png:before {
  content: "\e600";
}

.cancel-off-png:before {
  content: "\e601";
}

.star-on-png:before {
  content: "\f005";
}

.star-off-png:before {
  content: "\f006";
}

.star-half-png:before {
  content: "\f123";
}

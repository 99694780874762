.nav-tabs {
	margin-bottom: 15px;
}

.tab-content {
	margin-bottom: 35px;
}

.product_switcher {
	margin-bottom: 15px;
}

// .ms-container{
//   background: transparent url(asset-path('multi-select/arrow.png')) no-repeat 170px 80px !important;
// }

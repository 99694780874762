.ms-container {
    width:800px !important;
    background-position-x: 275px !important;
}

.ms-selectable, .ms-selection, .ms-list, .ms-elem-selectable {
    width:270px;
}

.ms-container ul.ms-list {
    width:270px !important;
}

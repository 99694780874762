td.card-icon {
  width: 150px;
}

p.card-title {
  padding:10px;
  font-weight:bold;
}

p.maincategory {
  padding-left:10px;
}

p.subcategory {
  padding-left:20px;
}

.card-box {
  display: block;
}

.card-info {
  width: 250px;
  text-align:center;
  padding:10px;
}

.card-categories {
  width: 85%;
  padding:10px;
}

.sortable_subcategories {
  padding:10px;
}

.card-preview {
  float: left;
  padding: 4px 4px 4px 4px;
  background: #fff;
  border: 1px solid #fff;
  -moz-box-shadow: 0px 2px 4px #555;
  -webkit-box-shadow: 0px 2px 4px #555;
  position: relative;
}

.ui-autocomplete {
  background-color:#2f2f2f;
  max-width:435px;
  border: 1px solid #202020;
  z-index:4000 !important;
  
  -moz-box-shadow: rgba(255, 255, 255, 0.1) 0 1px 0, rgba(0, 0, 0, 0.8) 0 1px 7px 0px;
  -webkit-box-shadow: rgba(255, 255, 255, 0.1) 0 1px 0, rgba(0, 0, 0, 0.8) 0 1px 7px 0px;
  -o-box-shadow: rgba(255, 255, 255, 0.1) 0 1px 0, rgba(0, 0, 0, 0.8) 0 1px 7px 0px;
  box-shadow: rgba(255, 255, 255, 0.1) 0 1px 0, rgba(0, 0, 0, 0.8) 0 1px 7px 0px;
}

.ui-autocomplete li {
  list-style-type: none;
  padding: 5px;
  border-bottom: 1px solid #202020;
}

.ui-helper-hidden-accessible {
   display:none;
}

.ui-autocomplete {
    max-height: 500px;
    overflow-y: auto;
    /* prevent horizontal scrollbar */
    overflow-x: hidden;
  }
  /* IE 6 doesn't support max-height
   * we use height instead, but this forces the menu to always be this tall
   */
  * html .ui-autocomplete {
    height: 500px;
  }
